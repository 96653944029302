import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {}
  body { 
    margin: 0;
    background: #E7E3D7;
    color: #3F3F3F;
    padding: 64px 16px;
    font-family: -apple-system, Roboto, sans-serif, serif;
  }
  a { 
    color: #D87EA2;
  }
`;

const Container = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Content = styled.div``;

const Header = styled.header`
  margin-top: 0;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
`;

const NameHeader = styled.h1`
  margin-left: 32px;
`;

const List = styled.ul`
  margin-bottom: 48px;
  list-style: none;
  padding-inline-start: 0;
`;

const ListItem = styled.li`
  font-size: 24px;
  font-weight: 300;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-bottom: 24px;
  margin-right: 18px;
  &::before {
    content: '${(props) => props.emoji}';
    margin-left: 18px;
    margin-right: 50px;
    display: block;
    width: 64px;
    min-width: 64px;
    height: 64px;
    min-height: 64px;
    font-size: 40px;
    vertical-align: middle;
    line-height: 64px;
    text-align: center;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }
`;

const Footer = styled.footer`
  text-align: center;
  font-size: 20px;
  font-weight: 300;
`;

const listItems: {
  readonly text: string;
  readonly emoji: string;
}[] = [
  {
    text: 'Professional software developer (C#, TypeScript)',
    emoji: '🖥',
  },
  {
    text: 'Photographer',
    emoji: '📷',
  },
  {
    text: 'Miniature painter',
    emoji: '👨‍🎨',
  },
  {
    text: 'Gamer',
    emoji: '🎮',
  },
];

const IndexPage = () => {
  return (
    <>
      <GlobalStyle />
      <title>Stephen Allred</title>
      <Container>
        <Content>
          <Header>
            <StaticImage
              alt='Picture of Stephen Allred'
              src='../images/me.jpg'
              loading='eager'
              placeholder='none'
              layout='fixed'
              height={100}
              width={100}
              quality={90}
              imgStyle={{
                borderRadius: 50,
              }}
              style={{
                borderRadius: 50,
                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.5)',
              }}
            />
            <NameHeader>Stephen Allred</NameHeader>
          </Header>
          <List>
            {listItems.map((item, index) => (
              <ListItem key={index} {...item}>
                {item.text}
              </ListItem>
            ))}
          </List>
          <Footer>
            <a href='mailto:hello@stephenallred.com'>Say hello</a> 👋
          </Footer>
        </Content>
      </Container>
    </>
  );
};

export default IndexPage;
